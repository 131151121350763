<template>
    <section class="join-page">
        <header class="mb-12 join-head">
            <h1 class="titulo-ocho revelar text-center mt-12" style="z-index: 2">
                Formá parte de la familia Ocho
            </h1>
            <a class="cta" @click="scrollYm('formRef')">
                <h2 class="mt-4 px-12 py-2">Aplicá aquí</h2>
            </a>
        </header>
        <v-container class="text-center mb-12">
            <h1 class="mb-8">¿Qué beneficios ofrecemos?</h1>
            <v-row>
                <v-col cols="12" lg="4" md="4" sm="4">
                    <img src="@/assets/join-driver/benefits-01.png" class="benefit-image" />
                    <h2 class="my-4">Aumentar tus ganancias</h2>
                    <p>Aprovechá las zonas concurridas y ganá con nuestras bonificaciones extra</p>
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="4">
                    <img src="@/assets/join-driver/benefits-02.png" class="benefit-image"/>
                    <h2 class="my-4">Tarifas competitivas</h2>
                    <p>
                        Pagamos una tarifa competitiva por cada pedido que entregue.
                        También puede obtener propinas de los clientes para ayudarle
                        a alcanzar sus objetivos de ganancias.
                    </p>
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="4">
                    <img src="@/assets/join-driver/benefits-03.png" class="benefit-image"/>
                    <h2 class="my-4">Soporte, formación y tranquilidad</h2>
                    <p>
                        Nuestros equipos de soporte están siempre vos, ayudándote en
                        cada entrega y a cualquier consulta. También ofrecemos capacitación
                        para utilizar la aplicación de drivers de manera óptima.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="align-center justify-center" cols="12" lg="4" md="4" sm="4">
                    <img src="@/assets/join-driver/benefits-04.png" class="benefit-image"/>
                    <h2 class="my-4">Tu pago en poco tiempo</h2>
                    <p>Transferiremos tus ganancias semanalmente.</p>
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="4">
                    <img src="@/assets/join-driver/benefits-05.png" class="benefit-image"/>
                    <h2 class="my-4">Cientos de comercios afiliados en las principales ciudades</h2>
                    <p>
                        Somos una de las plataformas de entrega más activas del país y entregamos
                        pedidos en el área local todos los días.
                    </p>
                </v-col>
                <v-col cols="12" lg="4" md="4" sm="4">
                    <img src="@/assets/join-driver/benefits-06.png" class="benefit-image"/>
                    <h2 class="my-4">Kit ocho</h2>
                    <p>
                        Equipo altamente visible resaltando nuestra marca, cómodo y transpirable, 
                        adecuado para el clima en el que conducís
                    </p>
                </v-col>
            </v-row>
        </v-container>

        <div class="orange-background px-12 py-12 mb-12">
            <v-container>
                <h1 class="mb-8">¿Cómo funciona?</h1>
                
                <v-row>
                    <v-col class="px-8" cols="12" lg="4" md="4" sm="4">
                        <h2 class="my-4">1. Descargá la app para chavos</h2>
                        <img class="step-image" src="@/assets/join-driver/step-01.png"/>
                    </v-col>
                    <v-col class="px-8" cols="12" lg="4" md="4" sm="4">
                        <h2 class="my-4">2. Aceptá los pedidos y arrancá</h2>
                        <img class="step-image" src="@/assets/join-driver/step-02.png"/>
                    </v-col>
                    <v-col class="px-8" cols="12" lg="4" md="4" sm="4">
                        <h2 class="my-4">3. Alegrá el día de un cliente entregano su orden</h2>
                        <img class="step-image" src="@/assets/join-driver/step-03.png"/>
                    </v-col>
            </v-row>
            </v-container>
        </div>

        <div class="px-12 py-12">
            <v-container>
                <v-row>
                    <v-col cols="12" lg="8" md="8" sm="8">
                        <h1 class="mb-8">Preguntas frecuentes</h1>
                        <v-expansion-panels
                            elevation="10"
                            style="border-radius: 15px;">
                            <v-expansion-panel>
                                <v-expansion-panel-header>¿Necesito motocicleta propia?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Si, es necesario que dispongás de una motocicleta propia.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>¿Necesito mis documentos vigentes?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Si, necesitás la matrícula de moto y tu licencia vigente
                                    para trabajar con nosotros.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>¿Es obligatorio tener una mochila de entregar para comenzar a trabajar?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    No, tener la mochila no es obligatorio para empezar con tus
                                    primeras entregas, sin embargo, si es necesario que recibás
                                    la mochila que te en ocho te estaremos brindando según nuestras normativas.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>¿En qué parte del país tienen flota de drivers o motoristas?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Contamos con nuestro equipo de chavos en Tegucigalpa y San Pedro Sula.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>¿En qué partes de la ciudad me puedo conectar? </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    Contamos con cobertura en la Zona Centro y sur de Tegucigalpa y la
                                    Zona Centro en San Pedro Sula.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>¿Cómo realizamos los pagos?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    El pago lo hacemos de manera semana, todas las semanas nuestros
                                    chavos reciben su dinero, incluyendo bonificaciones ganadas y
                                    propinas de los usuarios.
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="4">
                        <h1>Requisitos</h1>
                        <h3>
                            <span style="color: #FF5900">&#10003;</span>
                            Licencia vigente
                        </h3>
                        <h3>
                            <span style="color: #FF5900">&#10003;</span>
                            Matrícula vigente
                        </h3>
                        <h3>
                            <span style="color: #FF5900">&#10003;</span>
                            Motocicleta
                        </h3>
                        <h3>
                            <span style="color: #FF5900">&#10003;</span>
                            Equipo de seguridad
                        </h3>
                        <h3>
                            <span style="color: #FF5900">&#10003;</span>
                            +18 años de edad
                        </h3>
                        <h3>
                            <span style="color: #FF5900">&#10003;</span>
                            Entusiasmo y buena actitud
                        </h3>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="success-message" v-if="success">
            <h1 class="primary--text">Datos enviados correctamente</h1>
            <h2>
                Tus datos serán revisados y
                te contactarán pronto
            </h2>
        </div>
        <v-container id="driver-form" class="mb-8 mt-12" ref="formRef">
            <v-card
                class="ma-auto"
                elevation="0">
                <v-card-title>
                    <h1 class="mb-8">Enviá tus datos</h1>
                </v-card-title>
                <v-card-text>
                    <v-form v-if="!success">
                        <v-row class="">
                            <v-col>
                                <v-text-field
                                    rounded
                                    filled
                                    clearable
                                    label="DNI"
                                    placeholder="Número de DNI"
                                    :rules="[rules.required, rules.dni]"
                                    @keypress="isNumber"
                                    v-model="driver.dni"/>
                                <v-text-field
                                    rounded
                                    filled
                                    clearable
                                    label="Nombre completo"
                                    placeholder="Nombre completo"
                                    :rules="[rules.required]"
                                    v-model="driver.name" />
                                <v-combobox
                                    rounded
                                    filled
                                    clearable
                                    label="Ciudad"
                                    :items="cities"
                                    :rules="[rules.required]"
                                    v-model="driver.city"/>
                                <v-text-field
                                    rounded
                                    filled
                                    clearable
                                    label="Correo Electrónico"
                                    placeholder="Correo electrónico"
                                    :rules="[rules.required, rules.email]"
                                    v-model="driver.email" />
                                <v-text-field
                                    filled
                                    rounded
                                    clearable
                                    label="Teléfono"
                                    placeholder="Teléfono"
                                    name="input-phone"
                                    :rules="[rules.required, rules.phone]"
                                    @keypress="isNumber"
                                    type="number"
                                    v-model="driver.phone" />
                                <v-checkbox
                                    label="Tiene licencia de motocicleta vigente"
                                    v-model="driver.hasLicense"/>
                                <v-menu
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        rounded
                                        filled
                                        clearable
                                        v-model="driver.registrationExpiry"
                                        label="Vencimiento matrícula de la motocicleta"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        rounded
                                        filled
                                        clearable
                                        v-model="driver.registrationExpiry"
                                        :rules="[rules.required]"
                                        :allowed-dates="allowedDates"
                                        :min="minDate"
                                        @input="menu = false"></v-date-picker>
                                </v-menu>
                                <v-card-text class="error--text" v-if="success === false">
                                    Ha ocurrido un error al enviar tus datos,
                                    por favor intenta de nuevo
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        block
                        height="56px"
                        v-bind:color="'primary'"
                        elevation="0"
                        @click="onsubmit"
                        :disabled="disabled || submitting">
                        Enviar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </section>
</template>
<script>
const today = new Date(new Date().setUTCHours(0, 0, 0, 0))
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const dniRegex = /^\d{13}$/
const phoneRegex = /^\d{8}$/

export default {
    data: () => ({
        dateMenu: false,
        submitting: false,
        success: undefined,
        rules: {
            required: value => !!value || "Requerido",
            dni: (v) => {
                return dniRegex.test(v) || "DNI Invalído";
            },
            email: (v) => {
                return emailRegex.test(v) || "Correo Invalído";
            },
            phone: (v) => {
                return phoneRegex.test(v) || "Teléfono Invalído";
            }
        },
        cities: [],
        minDate: today.toISOString(),
        driver: {}
    }),
    computed: {
        disabled () {
            return (
                !dniRegex.test(this.driver.dni) ||
                !this.driver.name ||
                !this.driver.registrationExpiry ||
                !emailRegex.test(this.driver.email) ||
                !phoneRegex.test(this.driver.phone) ||
                !this.driver.city
            )
        }
    },
    async mounted () {
        try {
            const response = await fetch(
                `${process.env.VUE_APP_FUNCTIONS}${process.env.VUE_APP_FUNCTIONS_CITYNAMES}`
            )
            const json = await response.json()
            this.cities = json.map(val => val.name)
        } catch {
            error.log("Error fetching cities")
        }
    },
    methods: {
        getOffset(topAll) {
            var _x = 0;
            var _y = 0;
            while (topAll && !isNaN(topAll.offsetLeft) && !isNaN(topAll.offsetTop)) {
                _x += topAll.offsetLeft - topAll.scrollLeft;
                _y += topAll.offsetTop - topAll.scrollTop;
                topAll = topAll.offsetParent;
            }
            return { top: _y, left: _x };
        },
        scrollYm(item) {
            const ref = {
                formRef: this.$refs.formRef,
                typeOrderForm: this.$refs.typeOrderForm,
                typeOrderEntrega: this.$refs.typeOrderEntrega,
            };

            var topAll = ref[item];
            // console.debug(topAll);
            var y = this.getOffset(topAll).top;
            // console.debug(y);
            window.scroll({
                top: y + -100,
                left: 0,
                behavior: "smooth",
            });
        },
        onsubmit () {
            const driver = {...this.driver}
            driver.hasLicense = driver.hasLicense ? "si" : "no"
            const data = {
                service_id: process.env.VUE_APP_EMAIL_SERVICE_ID,
                template_id: process.env.VUE_APP_EMAIL_DRIVER_TEMPLATE_ID,
                user_id: process.env.VUE_APP_EMAIL_PUBLIC_KEY,
                template_params: driver
            }

            this.submitting = true
            fetch(
                process.env.VUE_APP_DRIVER_FORM_URL,
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: JSON.stringify(data),
                }
            )
            .then(response => this.success = response.ok)
            .catch(_ => this.success = false)
            .finally(_ => this.submitting = false)
        },
        isNumber (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        allowedDates: val => {
            return (
                new Date(val) >= today
            )
        }
    }
}
</script>

<style  src="@/main.scss" lang="scss"></style>
<style lang="scss">
    @import "@/_responsive.scss";
    body {
        scroll-behavior: smooth;
    }

    .success-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px;
        text-align: center;
    }
    .join-page {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: 100vh;
    }
    .join-head {
        padding: 32px;
        width: 100%;
        height: 700px;
        padding-top: 120px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        background-color: #ff5900;
        color: #fff;
        clip-path: ellipse(80% 80% at 50% 15%);
        @include responsive(mobile) {
          clip-path: ellipse(120% 70% at 50% 20%);
        }
        background-image: url('~@/assets/moto-ocho-desktop.png');
        background-position: bottom;
        background-size: cover;
    }
    .cta {
        text-decoration: none;
        h2 {
            background-color: white;
            border-radius: 27px;
            color: #ff5900;
        }
    }
    .step-image {
        width: 100%;
    }
    .benefit-image {
        width: 50%;
    }
    .orange-background {
        background-color: #ff5900;
        color: #fff;
        width: 100%;
    }
    .faq {
        width: 100%;
    }
</style>